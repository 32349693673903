import logError from './error-logger'

export default {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	configure: (configuration: any, onError: () => void) => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			;(window as any).CollectJS.configure(configuration)
		} catch (error) {
			onError()
			if (error instanceof Error)
				logError(
					`Error during NMI CollectJs configuration, original message:${error?.message}`,
					error?.stack,
					null,
					null,
					error
				)
			else
				logError(
					`Error during NMI CollectJs configuration, original message:${String(error)}`,
					null,
					null,
					null,
					error
				)
			throw error
		}
	},
	startPaymentRequest: (onError: () => void) => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			;(window as any).CollectJS.startPaymentRequest()
		} catch (error) {
			onError()
			if (error instanceof Error)
				logError(
					`Error during NMI CollectJs startPaymentRequest, original message:${error?.message}`,
					error?.stack,
					null,
					null,
					error
				)
			else
				logError(
					`Error during NMI CollectJs startPaymentRequest, original message:${String(error)}`,
					null,
					null,
					null,
					error
				)
			throw error
		}
	},
}
