import React from 'react'
import { useTranslation } from 'react-i18next'
import { PayCard } from '../../../../store/types/appState'
import { CreditCardLabel, TenderType } from '../../../../types/api/apiEnums'
import Modal from '../../../ui/modal/Modal/Modal'
import { creditCardLogoSrc } from '../../../../utils/utils'
import { ReactComponent as ECheck2Icon } from '../../../../assets/e-check_2.svg'
import classes from './DeletePaymentMethod.module.css'

export type Props = {
	cancel: () => void
	delete: (PayCard: PayCard) => void
	selectedCard: PayCard | null
}

const DeletePaymentMethod = (props: Props) => {
	const [t] = useTranslation()

	const deleteHandler = () => {
		if (props.selectedCard) {
			props.delete(props.selectedCard)
			props.cancel()
		}
	}

	return (
		<>
			<Modal
				id={`delete${props.selectedCard?.type}`}
				cancel={() => props.cancel()}
				ok={deleteHandler}
				title={
					props.selectedCard?.type === TenderType.CreditCard
						? t('payment-methods.delete-credit-card')
						: t('payment-methods.delete-echeck')
				}
				message={
					props.selectedCard?.type === TenderType.CreditCard
						? t('payment-methods.delete-credit-card-question')
						: t('payment-methods.delete-echeck-question')
				}
				okText={t('delete')}
				cancelText={t('cancel')}
			>
				<div className={classes.DeleteBody}>
					{props.selectedCard?.type === TenderType.CreditCard ? (
						<>
							<img
								className={classes.PaymentCardLogo}
								src={creditCardLogoSrc(props.selectedCard?.creditCardType)}
								alt={props.selectedCard?.creditCardType.toString()}
							/>
							<div className={classes.PaymentCardText}>
								{CreditCardLabel.get(props.selectedCard?.creditCardType)}
								<b>*{props.selectedCard?.last4Digits}</b>
							</div>
						</>
					) : (
						<div className={classes.PaymentCardBody}>
							<ECheck2Icon className={classes.PaymentCardLogo} />
							<div className={classes.PaymentCardECheckText}>
								<b>*{props.selectedCard?.last4Digits}</b>
							</div>
						</div>
					)}
				</div>
			</Modal>
		</>
	)
}

export default DeletePaymentMethod
