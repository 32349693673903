import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { appInsightsConfig } from '../../utils/configs'

const tokenRegex = /^(?:[\w-]*\.){2}[\w-]*$/

function MaskUriToken(uri: string) {
	if (!uri) {
		return uri
	}
	const pathLastElementIndex = uri.lastIndexOf('/') + 1
	const lastElement = uri.substring(pathLastElementIndex)

	const isToken = lastElement.match(tokenRegex)
	if (isToken) {
		const isTokenPresent = lastElement.length > 0
		return uri.substring(0, pathLastElementIndex) + (isTokenPresent ? ':token' : '')
	} else {
		return uri
	}
}

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
	config: {
		connectionString: appInsightsConfig.connectionString,
		extensions: [reactPlugin],
		extensionConfig: {},
		enableAutoRouteTracking: true,
		disableAjaxTracking: false,
		autoTrackPageVisitTime: true,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		correlationHeaderDomains: appInsightsConfig.domainsToTrack,
	},
})

if (appInsightsConfig.connectionString) {
	appInsights.loadAppInsights()
	appInsights.trackPageView()

	appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
		envelope.tags = envelope.tags || []
		if (!envelope.tags['ai.cloud.role']) {
			envelope.tags['ai.cloud.role'] = appInsightsConfig.applicationName
		}

		if (envelope.data) {
			envelope.data.ApplicationName = appInsightsConfig.applicationName
		}

		if (envelope.baseData) {
			if (envelope.baseData.uri) {
				envelope.baseData.uri = MaskUriToken(envelope.baseData.uri)
			}
			if (envelope.baseData.refUri) {
				envelope.baseData.refUri = MaskUriToken(envelope.baseData.refUri)
			}
		}

		if (envelope.ext && envelope.ext.trace.name) {
			envelope.ext.trace.name = MaskUriToken(envelope.ext.trace.name)
		}
	})
}

export { reactPlugin, appInsights }
