import React, { useEffect } from 'react'
import { AppState } from '../../../store/types/appState'
import { useDispatch, useSelector } from 'react-redux'
import useLoadStripe from '../../../hooks/useLoadStripe'
import { Elements } from '@stripe/react-stripe-js'
import Loader from '../../ui/Loader/Loader'
import StripePaymentElementsForm from './StripePaymentElementsForm'
import { createPaymentIntent } from '../../../store/actions/stripe'

const StripePaymentElementsFormWrapper = () => {
	const dispatch = useDispatch()

	const paymentIntentClientSecret = useSelector<AppState, string>((state) => state.stripe.paymentIntentClientSecret)

	useEffect(() => {
		if (!paymentIntentClientSecret) dispatch(createPaymentIntent())
	}, [dispatch, paymentIntentClientSecret])

	const isLoading = useSelector<AppState, boolean>((state) => state.stripe.loading)

	const { stripePromise } = useLoadStripe()

	return (
		<>
			{isLoading && <Loader />}
			{paymentIntentClientSecret != '' && (
				<Elements options={{ clientSecret: paymentIntentClientSecret }} stripe={stripePromise}>
					<StripePaymentElementsForm />
				</Elements>
			)}
		</>
	)
}

export default StripePaymentElementsFormWrapper
