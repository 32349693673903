import * as actionTypes from './actionTypes'
import { AppState, CreditCard, ECheck, PaymentMethodsState, ProviderType } from '../types/appState'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from '../../utils/axios-tezpay'
import ApiMethods from '../../constants/urls'
import { setError } from '.'
import { addNMIPaymentMethod, deleteNMIPaymentMethod } from './nmi'
import { TenderType } from '../../types/api/apiEnums'

export const getPaymentMethodsStart = () => {
	return {
		type: actionTypes.GET_PAYMENT_METHODS_START as typeof actionTypes.GET_PAYMENT_METHODS_START,
	}
}

export const getPaymentMethodsSuccess = (availableCreditCards: Array<CreditCard>, availableEChecks: Array<ECheck>) => {
	return {
		type: actionTypes.GET_PAYMENT_METHODS_SUCCESS as typeof actionTypes.GET_PAYMENT_METHODS_SUCCESS,
		availableCreditCards,
		availableEChecks,
	}
}

export const getPaymentMethodsFail = () => {
	return {
		type: actionTypes.GET_PAYMENT_METHODS_FAIL as typeof actionTypes.GET_PAYMENT_METHODS_FAIL,
	}
}

export const getPaymentMethods = () => {
	return (dispatch: ThunkDispatch<PaymentMethodsState, object, AnyAction>) => {
		dispatch(getPaymentMethodsStart())
		axios
			.get(ApiMethods.Portal.PaymentMethods)
			.then((response) => {
				const data = response.data.data
				dispatch(
					getPaymentMethodsSuccess(
						data.creditCards.map(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(c: any) =>
								({
									creditCardType: c.type,
									id: c.providerCreditCardId,
									last4Digits: c.last4Digits,
								}) as CreditCard
						),
						data.eChecks.map(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(c: any) =>
								({
									id: c.providerBankAccountId,
									last4Digits: c.last4Digits,
									routingNumberLast4Digits: c.routingNumberLast4Digits,
								}) as ECheck
						)
					)
				)
			})
			.catch((error) => {
				console.error(error)
				dispatch(getPaymentMethodsFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export const addPaymentMethodStart = () => {
	return {
		type: actionTypes.ADD_PAYMENT_METHOD_START as typeof actionTypes.ADD_PAYMENT_METHOD_START,
	}
}

export const addPaymentMethodSuccess = () => {
	return {
		type: actionTypes.ADD_PAYMENT_METHOD_SUCCESS as typeof actionTypes.ADD_PAYMENT_METHOD_SUCCESS,
	}
}

export const addPaymentMethodDecline = () => {
	return {
		type: actionTypes.ADD_PAYMENT_METHOD_DECLINE as typeof actionTypes.ADD_PAYMENT_METHOD_DECLINE,
	}
}

export const addPaymentMethodFail = () => {
	return {
		type: actionTypes.ADD_PAYMENT_METHOD_FAIL as typeof actionTypes.ADD_PAYMENT_METHOD_FAIL,
	}
}

export const addPaymentMethod = () => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(addPaymentMethodStart())

		const state = getState()

		switch (state.merchant.providerType) {
			case ProviderType.NMI:
			case ProviderType.ProPayCNP_NMI:
				dispatch(addNMIPaymentMethod())
				break
			default:
				console.error('providerType is not known')
				dispatch(setError('error.unknown-error'))
				break
		}
	}
}

export const deletePaymentMethodStart = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_METHOD_START as typeof actionTypes.DELETE_PAYMENT_METHOD_START,
	}
}

export const deletePaymentMethodSuccess = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_METHOD_SUCCESS as typeof actionTypes.DELETE_PAYMENT_METHOD_SUCCESS,
	}
}

export const deletePaymentMethodDecline = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_METHOD_DECLINE as typeof actionTypes.DELETE_PAYMENT_METHOD_DECLINE,
	}
}

export const deletePaymentMethodFail = () => {
	return {
		type: actionTypes.DELETE_PAYMENT_METHOD_FAIL as typeof actionTypes.DELETE_PAYMENT_METHOD_FAIL,
	}
}

export const deletePaymentMethod = (paymentMethodId: string, tenderType: TenderType) => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(deletePaymentMethodStart())

		const state = getState()

		switch (state.merchant.providerType) {
			case ProviderType.NMI:
			case ProviderType.ProPayCNP_NMI:
				dispatch(deleteNMIPaymentMethod(paymentMethodId, tenderType))
				break
			default:
				console.error('providerType is not known')
				dispatch(setError('error.unknown-error'))
				break
		}
	}
}

export type Actions =
	| ReturnType<typeof getPaymentMethodsStart>
	| ReturnType<typeof getPaymentMethodsSuccess>
	| ReturnType<typeof getPaymentMethodsFail>
	| ReturnType<typeof addPaymentMethodStart>
	| ReturnType<typeof addPaymentMethodSuccess>
	| ReturnType<typeof addPaymentMethodDecline>
	| ReturnType<typeof addPaymentMethodFail>
	| ReturnType<typeof deletePaymentMethodStart>
	| ReturnType<typeof deletePaymentMethodSuccess>
	| ReturnType<typeof deletePaymentMethodDecline>
	| ReturnType<typeof deletePaymentMethodFail>
