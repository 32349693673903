import * as actionTypes from '../actions/actionTypes'
import { ErrorState } from '../types/appState'
import { Actions } from '../actions/error'

const initialState: ErrorState = {
	message: null,
	clearable: true,
	isError: false,
}

const error = (state: ErrorState, message: string, clearable: boolean) => {
	return {
		...state,
		message: message,
		clearable: clearable,
		isError: true,
	}
}

const clearError = (state: ErrorState) => {
	return {
		...state,
		message: null,
		clearable: true,
		isError: false,
	}
}

const reducer = (state: ErrorState = initialState, action: Actions): ErrorState => {
	switch (action.type) {
		case actionTypes.ERROR:
			return error(state, action.error, action.clearable)
		case actionTypes.CLEAR_ERROR:
			return clearError(state)
		default:
			return state
	}
}

export default reducer
