import {
	SET_REDIRECT_TYPE,
	GET_REDIRECT_SETTINGS_START,
	GET_REDIRECT_SETTINGS_FAIL,
	GET_REDIRECT_SETTINGS_SUCCESS,
} from '../actions/actionTypes'
import { RedirectState, RedirectType } from '../types/appState'
import { Actions } from '../actions/redirect'
import { PortalHideControl } from '../../types/api/apiEnums'

const initialState: RedirectState = {
	redirectType: 0,
	hideControls: null,
	loading: false,
}

const error = (state: RedirectState, redirectType: RedirectType) => {
	return {
		...state,
		redirectType: redirectType,
	}
}

const setControlToHide = (state: RedirectState, hideControls: PortalHideControl | null) => {
	return {
		...state,
		hideControls: hideControls,
		loading: false,
	}
}

const reducer = (state: RedirectState = initialState, action: Actions): RedirectState => {
	switch (action.type) {
		case SET_REDIRECT_TYPE:
			return error(state, action.redirectType)
		case GET_REDIRECT_SETTINGS_START:
			return { ...state, loading: true }
		case GET_REDIRECT_SETTINGS_FAIL:
			return { ...state, loading: false }
		case GET_REDIRECT_SETTINGS_SUCCESS:
			return setControlToHide(state, action.hideControls)
		default:
			return state
	}
}

export default reducer
