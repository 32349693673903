import * as actionTypes from '../actions/actionTypes'
import { ManagePaymentMethodsState, ManagePaymentMethodsStep } from '../types/appState'
import * as managePaymentMethods from '../actions/managePaymentMethods'
import * as paymentMethods from '../actions/paymentMethods'

const initialState: ManagePaymentMethodsState = {
	step: ManagePaymentMethodsStep.ChooseMethod,
	redirectUrl: '',
	loading: false,
}

const setStep = (state: ManagePaymentMethodsState, step: ManagePaymentMethodsStep) => {
	return {
		...state,
		step: step,
	}
}

const restoreManagePaymentMethodsPostInit = (state: ManagePaymentMethodsState) => {
	return {
		...state,
		step: ManagePaymentMethodsStep.ChooseMethod,
	}
}

const getManagePaymentMethodsStart = (state: ManagePaymentMethodsState) => {
	return {
		...state,
		loading: true,
	}
}

const getManagePaymentMethodsSuccess = (state: ManagePaymentMethodsState, redirectUrl: string) => {
	return {
		...state,
		loading: false,
		redirectUrl: redirectUrl,
	}
}

const getManagePaymentMethodsFail = (state: ManagePaymentMethodsState) => {
	return {
		...state,
		loading: false,
	}
}

const reducer = (
	state: ManagePaymentMethodsState = initialState,
	action: managePaymentMethods.Actions | paymentMethods.Actions
): ManagePaymentMethodsState => {
	switch (action.type) {
		case actionTypes.SET_MANAGE_PAYMENT_METHODS_STEP:
			return setStep(state, action.step)
		case actionTypes.RESTOR_MANAGE_PAYMENT_METHODS_POST_INIT:
		case actionTypes.ADD_PAYMENT_METHOD_SUCCESS:
		case actionTypes.ADD_PAYMENT_METHOD_DECLINE:
		case actionTypes.ADD_PAYMENT_METHOD_FAIL:
		case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS:
		case actionTypes.DELETE_PAYMENT_METHOD_DECLINE:
		case actionTypes.DELETE_PAYMENT_METHOD_FAIL:
			return restoreManagePaymentMethodsPostInit(state)
		case actionTypes.GET_MANAGE_PAYMENT_METHODS_START:
			return getManagePaymentMethodsStart(state)
		case actionTypes.GET_MANAGE_PAYMENT_METHODS_SUCCESS:
			return getManagePaymentMethodsSuccess(state, action.redirectUrl)
		case actionTypes.GET_MANAGE_PAYMENT_METHODS_FAIL:
			return getManagePaymentMethodsFail(state)
		default:
			return state
	}
}

export default reducer
