import * as actionTypes from './actionTypes'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { auth, setError } from '.'
import { AppState, UserTimerState } from '../types/appState'
import axios from '../../utils/axios-tezpay'
import ApiMethods from '../../constants/urls'

export const defaultUserTimerDurationInMs = 900000

export const getUserTimerStart = () => {
	return {
		type: actionTypes.START_USER_TIMER_SUCCESS as typeof actionTypes.START_USER_TIMER_SUCCESS,
	}
}

export const getUserTimerFail = () => {
	return {
		type: actionTypes.START_USER_TIMER_FAIL as typeof actionTypes.START_USER_TIMER_FAIL,
	}
}

export const getUserTimerSuccess = (data: UserTimerState) => {
	return {
		type: actionTypes.START_USER_TIMER_SUCCESS as typeof actionTypes.START_USER_TIMER_SUCCESS,
		data: data,
	}
}

export const setUserTimerExpired = (isUserTimerExpired: boolean) => {
	return {
		type: actionTypes.SET_USER_TIMER_EXPIRED as typeof actionTypes.SET_USER_TIMER_EXPIRED,
		isUserTimerExpired,
	}
}

export const setUserTimerDuration = (userTimerDuration: number) => {
	return {
		type: actionTypes.SET_USER_TIMER_DURATION as typeof actionTypes.SET_USER_TIMER_DURATION,
		userTimerDuration,
	}
}

export const getUserTimer = () => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>) => {
		dispatch(getUserTimerStart())
		axios
			.get(ApiMethods.Portal.UserTimer)
			.then((res) => {
				dispatch(getUserTimerSuccess(res.data.data))
				if (res.data.data) {
					const userExpireDateUtc = res.data.data.userTimerExpireDateUtc as string
					const utcTime = res.data.data.serverCurrentDateUtc as string
					if (userExpireDateUtc) {
						const userSessionDuration = Math.abs(
							new Date(userExpireDateUtc).getTime() - new Date(utcTime).getTime()
						)
						dispatch(setUserTimerDuration(userSessionDuration))
					} else {
						dispatch(setUserTimerDuration(defaultUserTimerDurationInMs))
					}
				}
			})
			.catch((error) => {
				console.error(error)
				dispatch(getUserTimerFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.sessionExpired', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export type Actions =
	| ReturnType<typeof auth>
	| ReturnType<typeof getUserTimerSuccess>
	| ReturnType<typeof setUserTimerExpired>
	| ReturnType<typeof setUserTimerDuration>
