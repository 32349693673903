import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { setError } from '.'
import ApiMethods from '../../constants/urls'
import axios from '../../utils/axios-tezpay'
import { AppState } from '../types/appState'
import * as actionTypes from './actionTypes'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { config } from '../../utils/configs'
import { NavigateFunction } from 'react-router-dom'

export const createCheckoutSession = () => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>) => {
		dispatch(createCheckoutSessionStart())

		const url = ApiMethods.Portal.StripeChekoutSession.Base

		axios
			.post(url)
			.then((response) => {
				dispatch(createCheckoutSessionSuccess(response.data.data.clientSecret))
			})
			.catch((error) => {
				dispatch(createCheckoutSessionFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
				else if (error.response && error.response.data.error.errorCode === 2019)
					dispatch(setError('error.transactionAmountTooSmall', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export const createPaymentIntent = () => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>) => {
		dispatch(createPaymentIntentStart())

		const url = ApiMethods.Portal.StripePaymentIntent.Base

		axios
			.post(url)
			.then((response) => {
				dispatch(createPaymentIntentSuccess(response.data.data.clientSecret))
			})
			.catch((error) => {
				dispatch(createPaymentIntentFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
				else if (error.response && error.response.data.error.errorCode === 2019)
					dispatch(setError('error.transactionAmountTooSmall', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export const confirmPaymentIntent = (stripe: Stripe, elements: StripeElements, navigate: NavigateFunction) => {
	return async (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(confirmPaymentIntentStart())

		const auth = getState().auth

		await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: config.returnUrl.replace('{token}', auth.token),
			},
			redirect: 'if_required',
		})

		navigate(`/${auth.token}/return`)
	}
}

export const createCheckoutSessionStart = () => {
	return {
		type: actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_START as typeof actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_START,
	}
}

export const createCheckoutSessionSuccess = (clientSecret: string) => {
	return {
		type: actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS as typeof actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS,
		clientSecret: clientSecret,
	}
}

export const createCheckoutSessionFail = () => {
	return {
		type: actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_FAIL as typeof actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_FAIL,
	}
}

export const createPaymentIntentStart = () => {
	return {
		type: actionTypes.STRIPE_CREATE_PAYMENT_INTENT_START as typeof actionTypes.STRIPE_CREATE_PAYMENT_INTENT_START,
	}
}

export const createPaymentIntentSuccess = (clientSecret: string) => {
	return {
		type: actionTypes.STRIPE_CREATE_PAYMENT_INTENT_SUCCESS as typeof actionTypes.STRIPE_CREATE_PAYMENT_INTENT_SUCCESS,
		clientSecret: clientSecret,
	}
}

export const createPaymentIntentFail = () => {
	return {
		type: actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_FAIL as typeof actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_FAIL,
	}
}

export const confirmPaymentIntentStart = () => {
	return {
		type: actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_START as typeof actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_START,
	}
}

export const confirmPaymentIntentFail = () => {
	return {
		type: actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_FAIL as typeof actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_FAIL,
	}
}

export type Actions =
	| ReturnType<typeof createCheckoutSessionSuccess>
	| ReturnType<typeof createCheckoutSessionStart>
	| ReturnType<typeof createCheckoutSessionFail>
	| ReturnType<typeof confirmPaymentIntentStart>
	| ReturnType<typeof confirmPaymentIntentFail>
	| ReturnType<typeof createPaymentIntentStart>
	| ReturnType<typeof createPaymentIntentFail>
	| ReturnType<typeof createPaymentIntentSuccess>
