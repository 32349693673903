import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { auth, getMerchant } from '../../store/actions'
import { setRedirectType } from '../../store/actions/redirect'
import { createAutoPayment, initializeAutoPayment } from '../../store/actions/transaction'
import { setUserTimerExpired, getUserTimer } from '../../store/actions/userTimer'
import { AppState, RedirectType } from '../../store/types/appState'
import GenericPayment from './GenericPayment'
import { authSession } from '../../store/actions/auth'

const AutoPayment = () => {
	const dispatch = useDispatch()

	const { token } = useParams()

	const authToken = useSelector<AppState>((state) => state.auth.token)
	const isTokenValid = useSelector<AppState>((state) => state.auth.isTokenValid)
	const userSessionDuration = useSelector<AppState, number>((state) => state.userTimer.userTimerDuration)
	const isUserTimerExpired = useSelector<AppState, boolean>((state) => state.userTimer.isUserTimerExpired)

	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

	useEffect(() => {
		if (userSessionDuration && !isUserTimerExpired) {
			setTimer(
				setTimeout(() => {
					dispatch(setUserTimerExpired(true))
				}, userSessionDuration)
			)
		}
	}, [userSessionDuration, dispatch, isUserTimerExpired])

	useEffect(() => {
		return () => {
			if (timer !== null) {
				clearTimeout(timer)
			}
		}
	}, [timer])

	useEffect(() => {
		if (authToken !== null && isTokenValid === true) {
			dispatch(initializeAutoPayment())
			dispatch(getUserTimer())
			dispatch(getMerchant())
		}
	}, [authToken, isTokenValid, dispatch])

	useEffect(() => {
		dispatch(setRedirectType(RedirectType.AutoPayment))
	}, [dispatch])

	useEffect(() => {
		dispatch(auth(token!))
	}, [token, dispatch])

	useEffect(() => {
		if (authToken !== null) {
			dispatch(authSession())
		}
	}, [authToken, dispatch])

	return (
		<GenericPayment
			payHandler={() => {
				dispatch(createAutoPayment())
				if (timer) clearTimeout(timer)
			}}
		/>
	)
}

export default AutoPayment
