import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaymentLayout from '../../components/ui/layout/PaymentLayout/PaymentLayout'
import { AppState, TransactionState, MerchantState, RedirectStatusState } from '../../store/types/appState'
import { getRedirectData } from '../../store/actions/redirect'
import ExternalForm from '../../components/Payment/CustomForm/CustomForm'
import { getUserTimer } from '../../store/actions/userTimer'

const CustomPayment = () => {
	const dispatch = useDispatch()

	const transactionData = useSelector<AppState, TransactionState>((state) => state.transaction)

	const merchant = useSelector<AppState, MerchantState>((state) => state.merchant)

	const redirectStatus = useSelector<AppState, RedirectStatusState>((state) => state.redirectStatus)

	const authToken = useSelector<AppState>((state) => state.auth.token)
	const isTokenValid = useSelector<AppState>((state) => state.auth.isTokenValid)

	useEffect(() => {
		if (authToken !== null && isTokenValid === true) {
			dispatch(getRedirectData())
			dispatch(getUserTimer())
		}
	}, [authToken, isTokenValid, dispatch])

	const backHandler = () => {
		window.history.back()
	}

	const loading = transactionData.loading || merchant.loading || redirectStatus.loading

	return (
		<>
			<PaymentLayout isLoading={loading} showContentOnLoading={true}>
				<ExternalForm back={backHandler} providerType={merchant.providerType} />
			</PaymentLayout>
		</>
	)
}

export default CustomPayment
