import React, { useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import classes from './Loader.module.css'
import loaderAnimation from '../../../assets/loader.json'

const Loader = () => {
	const loaderRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (loaderRef.current)
			lottie.loadAnimation({
				animationData: loaderAnimation,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				container: loaderRef.current,
			})
	})

	return (
		<>
			<div className={classes.Backdrop}></div>
			<div className={classes.Loader} ref={loaderRef}></div>
		</>
	)
}

export default Loader
