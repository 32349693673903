import * as actionTypes from '../actions/actionTypes'
import { MerchantState, ProviderType, MerchantConfiguration, VerificationType } from '../types/appState'
import { Actions } from '../actions/merchant'

const initialState: MerchantState = {
	loading: false,
	achEnabled: false,
	ccEnabled: false,
	googlePayEnabled: false,
	applePayEnabled: false,
	showBackButton: false,
	configuration: null,
	merchantId: '',
	providerType: 0,
	verificationType: 0,
	availablePaymentMethodTypeCount: 0,
}

const getMerchantStart = (state: MerchantState) => {
	return {
		...state,
		loading: true,
	}
}

const getVerificationType = (providerType: ProviderType): VerificationType => {
	switch (providerType) {
		default:
			return VerificationType.None
	}
}

const getMerchantSuccess = (
	state: MerchantState,
	merchantId: string,
	providerType: ProviderType,
	configuration: MerchantConfiguration,
	ccEnabled: boolean,
	achEnabled: boolean,
	googlePayEnabled: boolean,
	applePayEnabled: boolean,
	showBackButton: boolean
) => {
	return {
		...state,
		merchantId: merchantId,
		providerType: providerType,
		configuration: configuration,
		ccEnabled: ccEnabled,
		achEnabled: achEnabled,
		googlePayEnabled: googlePayEnabled,
		applePayEnabled: applePayEnabled,
		showBackButton: showBackButton,
		verificationType: getVerificationType(providerType),
		loading: false,
		availablePaymentMethodTypeCount: countTrue([ccEnabled, achEnabled, googlePayEnabled, applePayEnabled]),
	}
}

const countTrue = (options: Array<boolean>) => {
	return options.map<number>((value) => (value ? 1 : 0)).reduce((partialSum, a) => partialSum + a, 0)
}

const getMerchantFail = (state: MerchantState) => {
	return {
		...state,
		loading: false,
	}
}

const reducer = (state: MerchantState = initialState, action: Actions): MerchantState => {
	switch (action.type) {
		case actionTypes.GET_MERCHANT_START:
			return getMerchantStart(state)
		case actionTypes.GET_MERCHANT_FAIL:
			return getMerchantFail(state)
		case actionTypes.GET_MERCHANT_SUCCESS:
			return getMerchantSuccess(
				state,
				action.merchantId,
				action.providerType,
				action.configuration,
				action.ccEnabled,
				action.achEnabled,
				action.googlePayEnabled,
				action.applePayEnabled,
				action.showBackButton
			)
		default:
			return state
	}
}

export default reducer
