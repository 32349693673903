import React from 'react'
import { CreditCardType } from '../../../types/api/apiEnums'
import classes from './PaymentUserTimeout.module.css'
import { useTranslation } from 'react-i18next'
import BottomButton from '../../ui/layout/BottomButtons/BottomButton'
import BottomButtons from '../../ui/layout/BottomButtons/BottomButtons'

export type Props = {
	creditCardType: CreditCardType
	last4Digits: string
	amount: number
	cancel: () => void
	tryAgain?: () => void
}

const PaymentUserTimeout = (props: Props) => {
	const [t] = useTranslation()
	return (
		<>
			<div className={classes.Ellipse}>
				<div className={classes.Header}>
					<div className={classes.X} />
					<div className={classes.UserTimeout}>
						<div className={classes.UserTimeoutText}>{t('payment.user-timeout')}</div>
					</div>
				</div>
				<BottomButtons>
					<BottomButton
						id='userTimeoutClose'
						className={`ButtonOutlined`}
						onClick={props.cancel}
						disabled={undefined}
						title={t('close')}
						grow={undefined}
					/>
				</BottomButtons>
			</div>
		</>
	)
}

export default PaymentUserTimeout
