import * as actionTypes from '../actions/actionTypes'
import { CreditCard, ECheck, PaymentMethodsState } from '../types/appState'
import { Actions } from '../actions/paymentMethods'

const initialState: PaymentMethodsState = {
	availableCreditCards: null,
	availableEChecks: null,
	loading: false,
}

const getPaymentMethodsSuccess = (
	state: PaymentMethodsState,
	availableCreditCards: Array<CreditCard>,
	availableEChecks: Array<ECheck>
) => {
	return {
		...state,
		availableCreditCards: availableCreditCards,
		availableEChecks: availableEChecks,
		loading: false,
	}
}

const setLoadingFalse = (state: PaymentMethodsState) => {
	return {
		...state,
		loading: false,
	}
}

const setLoadingTrue = (state: PaymentMethodsState) => {
	return {
		...state,
		loading: true,
	}
}

const deletePaymentMethodSuccess = (state: PaymentMethodsState) => {
	return {
		...state,
		loading: false,
		availableCreditCards: null,
		availableEChecks: null,
	}
}

const reducer = (state: PaymentMethodsState = initialState, action: Actions): PaymentMethodsState => {
	switch (action.type) {
		case actionTypes.GET_PAYMENT_METHODS_START:
		case actionTypes.ADD_PAYMENT_METHOD_START:
		case actionTypes.DELETE_PAYMENT_METHOD_START:
			return setLoadingTrue(state)
		case actionTypes.GET_PAYMENT_METHODS_FAIL:
		case actionTypes.ADD_PAYMENT_METHOD_SUCCESS:
		case actionTypes.ADD_PAYMENT_METHOD_FAIL:
		case actionTypes.ADD_PAYMENT_METHOD_DECLINE:
		case actionTypes.DELETE_PAYMENT_METHOD_FAIL:
		case actionTypes.DELETE_PAYMENT_METHOD_DECLINE:
			return setLoadingFalse(state)
		case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
			return getPaymentMethodsSuccess(state, action.availableCreditCards, action.availableEChecks)
		case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS:
			return deletePaymentMethodSuccess(state)
		default:
			return state
	}
}

export default reducer
