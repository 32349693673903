import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentMethods } from '../../store/actions'
import { getRedirectSettings } from '../../store/actions/redirect'
import { payForTransaction, initializeOneTimePayment } from '../../store/actions/transaction'
import { getUserTimer } from '../../store/actions/userTimer'
import { AppState, PaymentMethodsState, PaymentStep, TransactionState } from '../../store/types/appState'
import GenericPayment from './GenericPayment'

export interface PaymentProps {
	timer: NodeJS.Timeout | null
}

const Payment = (props: PaymentProps) => {
	const dispatch = useDispatch()

	const authToken = useSelector<AppState>((state) => state.auth.token)
	const isTokenValid = useSelector<AppState>((state) => state.auth.isTokenValid)

	useEffect(() => {
		if (authToken !== null && isTokenValid === true) {
			dispatch(initializeOneTimePayment())
			dispatch(getRedirectSettings())
			dispatch(getUserTimer())
		}
	}, [authToken, isTokenValid, dispatch])

	const transactionData = useSelector<AppState, TransactionState>((state) => state.transaction)

	const paymentMethods = useSelector<AppState, PaymentMethodsState>((state) => state.paymentMethods)

	useEffect(() => {
		if (
			transactionData.paymentStep === PaymentStep.ChooseMethod &&
			paymentMethods.availableCreditCards === null &&
			paymentMethods.availableEChecks === null
		) {
			dispatch(getPaymentMethods())
		} else if (
			transactionData.paymentStep === PaymentStep.ChooseCCMethod &&
			paymentMethods.availableCreditCards === null
		) {
			dispatch(getPaymentMethods())
		} else if (
			transactionData.paymentStep === PaymentStep.ChooseACHMethod &&
			paymentMethods.availableEChecks === null
		) {
			dispatch(getPaymentMethods())
		}
	}, [dispatch, transactionData.paymentStep, paymentMethods.availableCreditCards, paymentMethods.availableEChecks])

	return (
		<GenericPayment
			payHandler={() => {
				dispatch(payForTransaction())
				if (props.timer) clearTimeout(props.timer)
			}}
		/>
	)
}

export default Payment
