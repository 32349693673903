import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import logError from '../utils/error-logger'

const useScript = (url: string, dataset: Record<string, string>, error: () => void) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [reload, setReload] = useState(5)

	useDeepCompareEffect(() => {
		if (reload > 0) {
			const script = document.createElement('script')
			script.src = url
			script.async = true
			script.onload = () => {
				setIsLoaded(true)
			}
			script.onerror = () => {
				setReload(reload - 1)
				const err = new Error()
				logError(`Error loading script: ${url}`, null, null, null, err)
			}

			Object.entries(dataset).forEach((value) => (script.dataset[value[0]] = value[1]))

			document.body.appendChild(script)

			return () => {
				document.body.removeChild(script)
			}
		} else {
			error()
		}
	}, [url, dataset, reload])

	return isLoaded
}

export default useScript
