import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProviderType } from '../../../../store/types/appState'
import NmiAddECheck from './NMIAddECheck/NMIAddECheck'
import Header from '../../../ui/layout/Header/Header'

export type Props = {
	back: () => void
	next: (name: string, last4Digits: string) => void
	providerType: ProviderType
}

const AddECheck = (props: Props) => {
	const [t] = useTranslation()

	let eCheckComponent
	switch (props.providerType) {
		case ProviderType.NMI:
		case ProviderType.ProPayCNP_NMI:
			eCheckComponent = <NmiAddECheck next={props.next} />
			break
		default:
			eCheckComponent = null
			break
	}

	return (
		<>
			<Header title={t('payment.add-echeck')} subTitle={null} showBackButton={true} back={props.back} />
			{eCheckComponent}
		</>
	)
}

export default AddECheck
