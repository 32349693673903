import * as actionTypes from './actionTypes'

export const setError = (error: string, clearable = true) => {
	return {
		type: actionTypes.ERROR as typeof actionTypes.ERROR,
		error: error,
		clearable: clearable,
	}
}

export const clearError = () => {
	return {
		type: actionTypes.CLEAR_ERROR as typeof actionTypes.CLEAR_ERROR,
	}
}

export type Actions = ReturnType<typeof setError> | ReturnType<typeof clearError>
