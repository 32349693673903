import axios from '../../utils/axios-tezpay'
import * as actionTypes from './actionTypes'
import { ThunkDispatch } from 'redux-thunk'
import { AuthState } from '../types/appState'
import { AnyAction } from 'redux'
import ApiMethods from '../../constants/urls'
import { setError } from './error'

export const auth = (token: string) => {
	return {
		type: actionTypes.AUTH as typeof actionTypes.AUTH,
		token: token,
	}
}

export const authStart = () => {
	return {
		type: actionTypes.START_AUTH as typeof actionTypes.START_AUTH,
	}
}

export const authFail = () => {
	return {
		type: actionTypes.AUTH_FAIL as typeof actionTypes.AUTH_FAIL,
	}
}

export const authSuccess = (isTokenValid: boolean) => {
	return {
		type: actionTypes.AUTH_SUCCESS as typeof actionTypes.AUTH_SUCCESS,
		isTokenValid: isTokenValid,
	}
}

export const authSession = () => {
	return (dispatch: ThunkDispatch<AuthState, object, AnyAction>) => {
		dispatch(authStart())
		axios
			.post(ApiMethods.Portal.Auth)
			.then((response) => {
				const data = response.status === 200 ? true : false
				dispatch(authSuccess(data))
			})
			.catch((error) => {
				console.error(error)
				dispatch(authFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.sessionExpired', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export type Actions =
	| ReturnType<typeof auth>
	| ReturnType<typeof authStart>
	| ReturnType<typeof authFail>
	| ReturnType<typeof authSuccess>
