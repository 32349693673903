import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	AppState,
	ErrorState,
	MerchantState,
	NMIMerchantConfiguration,
	TransactionState,
} from '../../../store/types/appState'
import useScript from '../../../hooks/useScript'
import { payForNMIMobileWalletTransaction } from '../../../store/actions/nmi'
import { nmiConfig } from '../../../utils/configs'
import nmiCollectJs from '../../../utils/nmi-collectjs'
import paymentMethodclasses from '../PaymentMethods/PaymentMethods.module.css'
import Loader from '../../ui/Loader/Loader'
import { matchCreditCardType } from '../../../utils/nmi'

const NMIGooglePayButton = () => {
	const dispatch = useDispatch()
	const merchant = useSelector<AppState, MerchantState>((state) => state.merchant)
	const merchantConfiguration = merchant.configuration as NMIMerchantConfiguration

	const transaction = useSelector<AppState, TransactionState>((state) => state.transaction)

	const error = useSelector<AppState, ErrorState>((state) => state.error)

	const nmiErrorHandler = useCallback(() => {
		console.log('Error with loading NMI GooglePay')
	}, [])

	const isScriptLoaded = useScript(
		nmiConfig.collectJs,
		{
			tokenizationKey: merchantConfiguration.tokenizationKey,
		},
		() => {}
	)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [collectJsResponse, setCollectJsResponse] = useState<any>(null)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		if (collectJsResponse) {
			const ccType = matchCreditCardType(collectJsResponse.wallet.cardNetwork)
			dispatch(
				payForNMIMobileWalletTransaction(
					collectJsResponse.token as string,
					ccType,
					collectJsResponse.wallet.cardDetails
				)
			)
		}
	}, [collectJsResponse, dispatch])

	const configureNMI = useCallback(() => {
		nmiCollectJs.configure(
			{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				callback: (response: any) => {
					if (!error.isError) {
						setIsLoading(true)
						setCollectJsResponse(response)
					}
				},
				variant: 'inline',
				country: 'US',
				price: (transaction.amount / 100).toFixed(2),
				currency: merchantConfiguration.currencyCode,
				fields: {
					googlePay: {
						buttonType: 'plain',
						buttonColor: 'black',
					},
				},
			},
			nmiErrorHandler
		)

		return () => {
			nmiCollectJs.configure({}, nmiErrorHandler)
		}
	}, [nmiErrorHandler, error.isError, merchantConfiguration.currencyCode, transaction.amount])

	useEffect(() => {
		if (isScriptLoaded) {
			configureNMI()
		}
	}, [isScriptLoaded, configureNMI])

	return (
		<>
			{!error.isError && isLoading ? <Loader /> : null}
			<div className={paymentMethodclasses.GooglePayPaymentMethodWrapper}>
				<div id='googlepaybutton'></div>
			</div>
		</>
	)
}

export default NMIGooglePayButton
