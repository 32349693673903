import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import AutoPayment from './containers/Payment/AutoPayment'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from './components/ApplicationInsights/AppInsights'
import OneTimePayment from './containers/Payment/OneTimePayment'
import AfterPayment from './containers/Payment/AfterPayment'

const App = () => {
	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<Routes>
				<Route path='/:token' element={<OneTimePayment />} />
				<Route path='/:token/return' element={<AfterPayment />} />
				<Route path='/auto-payment/:token' element={<AutoPayment />} />
				<Route path='*' element={<div>Not Found</div>} />
			</Routes>
		</AppInsightsContext.Provider>
	)
}

export default App
