import * as actionTypes from './actionTypes'
import { ThunkDispatch } from 'redux-thunk'
import { TransactionState, TransactionStatus, PaymentStep, AppState, ProviderType } from '../types/appState'
import axios from '../../utils/axios-tezpay'
import ApiMethods from '../../constants/urls'
import { CreditCardType, TenderType } from '../../types/api/apiEnums'
import { setError } from '.'
import { AnyAction } from 'redux'

import { createNMIAutoPayment, payForNMITransaction, setNMITransactionCreditCard, setNMITransactionECheck } from './nmi'

export const restorePostInit = () => {
	return {
		type: actionTypes.RESTORE_POST_INIT as typeof actionTypes.RESTORE_POST_INIT,
	}
}

export const updateTransactionInfo = (
	last4Digits: string,
	creditCardType: CreditCardType,
	nextPaymentStep: PaymentStep
) => {
	return {
		type: actionTypes.UPDATE_TRANSACTION_INFO as typeof actionTypes.UPDATE_TRANSACTION_INFO,
		last4Digits: last4Digits,
		creditCardType: creditCardType,
		paymentStep: nextPaymentStep,
	}
}

export const getTransactionStart = () => {
	return {
		type: actionTypes.GET_TRANSACTION_START as typeof actionTypes.GET_TRANSACTION_START,
	}
}

export const getTransactionSuccess = (
	amount: number,
	status: TransactionStatus,
	redirectUrl: string,
	last4Digits: string,
	cardType: CreditCardType | null,
	tenderType: TenderType | null
) => {
	return {
		type: actionTypes.GET_TRANSACTION_SUCCESS as typeof actionTypes.GET_TRANSACTION_SUCCESS,
		amount: amount,
		status: status,
		redirectUrl: redirectUrl,
		last4Digits: last4Digits,
		cardType: cardType,
		tenderType: tenderType,
	}
}

export const getTransactionFail = () => {
	return {
		type: actionTypes.GET_TRANSACTION_FAIL as typeof actionTypes.GET_TRANSACTION_FAIL,
	}
}

export const getOneTimePaymentStart = () => {
	return {
		type: actionTypes.GET_ONE_TIME_PAYMENT_START as typeof actionTypes.GET_ONE_TIME_PAYMENT_START,
	}
}

export const getOneTimePaymentSuccess = (isSavingPaymentMethodAllowed: boolean) => {
	return {
		type: actionTypes.GET_ONE_TIME_PAYMENT_SUCCESS as typeof actionTypes.GET_ONE_TIME_PAYMENT_SUCCESS,
		isSavingPaymentMethodAllowed: isSavingPaymentMethodAllowed,
	}
}

export const getOneTimePaymentFail = () => {
	return {
		type: actionTypes.GET_ONE_TIME_PAYMENT_FAIL as typeof actionTypes.GET_ONE_TIME_PAYMENT_FAIL,
	}
}

export const initializeOneTimePayment = () => {
	return (dispatch: ThunkDispatch<TransactionState, object, AnyAction>) => {
		dispatch(getOneTimePaymentStart())
		axios
			.get(ApiMethods.Portal.RedirectOneTimePayment)
			.then((response) => {
				const data = response.data.data
				dispatch(getOneTimePaymentSuccess(data.isSavingPaymentMethodAllowed))
			})
			.catch((error) => {
				console.error(error)
				dispatch(getOneTimePaymentFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export const initializeAutoPayment = () => {
	return {
		type: actionTypes.SET_TRANSACTION_AUTO_PAYMENT as typeof actionTypes.SET_TRANSACTION_AUTO_PAYMENT,
		isSavingPaymentMethodAllowed: true,
	}
}

export const transactionStart = () => {
	return {
		type: actionTypes.TRANSACTION_START as typeof actionTypes.TRANSACTION_START,
	}
}

export const transactionCompleted = (transactionStatus: TransactionStatus) => {
	return {
		type: actionTypes.TRANSACTION_COMPLETED as typeof actionTypes.TRANSACTION_COMPLETED,
		transactionStatus: transactionStatus,
	}
}

export const transactionFail = () => {
	return {
		type: actionTypes.TRANSACTION_FAIL as typeof actionTypes.TRANSACTION_FAIL,
	}
}

export const payForTransaction = () => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(transactionStart())

		const state = getState()

		switch (state.merchant.providerType) {
			case ProviderType.NMI:
			case ProviderType.ProPayCNP_NMI:
				dispatch(payForNMITransaction())
				break
			default:
				console.error('providerType is not known')
				dispatch(setError('error.unknown-error'))
				break
		}
	}
}

export const createAutoPayment = () => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(transactionStart())

		const state = getState()

		switch (state.merchant.providerType) {
			case ProviderType.NMI:
			case ProviderType.ProPayCNP_NMI:
				dispatch(createNMIAutoPayment())
				break
			default:
				console.error('providerType is not known')
				dispatch(setError('error.unknown-error'))
				break
		}
	}
}

export const setTransactionCreditCard = (last4Digits: string, creditCardType: CreditCardType) => {
	return {
		type: actionTypes.SET_TRANSACTION_CREDIT_CARD as typeof actionTypes.SET_TRANSACTION_CREDIT_CARD,
		last4Digits,
		creditCardType,
	}
}

export const setDigitalWalletCreditCard = (last4Digits: string, creditCardType: CreditCardType) => {
	return {
		type: actionTypes.SET_TRANSACTION_DIGITAL_WALLET as typeof actionTypes.SET_TRANSACTION_DIGITAL_WALLET,
		last4Digits,
		creditCardType,
	}
}

export const setExistingTransactionCreditCard = (
	creditCardId: string,
	zipCode: string,
	cvv: string,
	last4Digits: string,
	creditCardType: CreditCardType
) => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(setTransactionCreditCard(last4Digits, creditCardType))
		switch (getState().merchant.providerType) {
			case ProviderType.NMI:
			case ProviderType.ProPayCNP_NMI:
				dispatch(setNMITransactionCreditCard(creditCardId, cvv))
				break
			default:
				console.error('providerType is not known')
				dispatch(setError('error.unknown-error'))
				break
		}
	}
}

export const setExistingTransactionECheck = (eCheckId: string, last4Digits: string) => {
	return (dispatch: ThunkDispatch<AppState, object, AnyAction>, getState: () => AppState) => {
		dispatch(setTransactionECheck(last4Digits, ''))
		switch (getState().merchant.providerType) {
			case ProviderType.NMI:
			case ProviderType.ProPayCNP_NMI:
				dispatch(setNMITransactionECheck(eCheckId))
				break
			default:
				console.error('providerType is not known')
				dispatch(setError('error.unkown-error'))
				break
		}
	}
}

export const setTransactionECheck = (last4Digits: string, name: string) => {
	return {
		type: actionTypes.SET_TRANSACTION_ECHECK as typeof actionTypes.SET_TRANSACTION_ECHECK,
		last4Digits,
		name,
	}
}

export const setPaymentStep = (paymentStep: PaymentStep) => {
	return {
		type: actionTypes.SET_PAYMENT_STEP as typeof actionTypes.SET_PAYMENT_STEP,
		paymentStep: paymentStep,
	}
}

export const addCreditCardStep = () => {
	return {
		type: actionTypes.ADD_CREDIT_CARD_STEP as typeof actionTypes.ADD_CREDIT_CARD_STEP,
	}
}

export const addECheckStep = () => {
	return {
		type: actionTypes.ADD_ECHECK_STEP as typeof actionTypes.ADD_ECHECK_STEP,
	}
}

export const setPaymentData = (savePaymentMethod: boolean) => {
	return {
		type: actionTypes.SET_PAYMENT_DATA as typeof actionTypes.SET_PAYMENT_DATA,
		savePaymentMethod: savePaymentMethod,
	}
}

export type Actions =
	| ReturnType<typeof getTransactionStart>
	| ReturnType<typeof getTransactionSuccess>
	| ReturnType<typeof getTransactionFail>
	| ReturnType<typeof updateTransactionInfo>
	| ReturnType<typeof restorePostInit>
	| ReturnType<typeof setTransactionCreditCard>
	| ReturnType<typeof setTransactionECheck>
	| ReturnType<typeof addCreditCardStep>
	| ReturnType<typeof addECheckStep>
	| ReturnType<typeof setPaymentData>
	| ReturnType<typeof setPaymentStep>
	| ReturnType<typeof transactionFail>
	| ReturnType<typeof transactionStart>
	| ReturnType<typeof transactionCompleted>
	| ReturnType<typeof setDigitalWalletCreditCard>
	| ReturnType<typeof getOneTimePaymentSuccess>
	| ReturnType<typeof getOneTimePaymentFail>
	| ReturnType<typeof getOneTimePaymentStart>
	| ReturnType<typeof initializeAutoPayment>
