// import trashIcon from '../../../../../assets/trash.svg';
import cx from 'classnames'
import React from 'react'
import { CreditCardType } from '../../../../types/api/apiEnums'
import { creditCardLogoSrc } from '../../../../utils/utils'
import classes from './CreditCard.module.scss'

const DOT = '•'

export type Props = {
	creditCardType: CreditCardType
	last4Digits: string
	isSelected: boolean
	select: () => void
	remove: () => void
	handleClickOutside: () => void
	id: string
}

const CreditCard = (props: Props) => {
	const { creditCardType, last4Digits, isSelected } = props
	const [isMouseMoved, setIsMouseMoved] = React.useState(false)

	// const removeHandler = (event: React.MouseEvent<HTMLImageElement>) => {
	//   event.stopPropagation();
	//   props.remove();
	// };

	const select = () => {
		if (!isMouseMoved && isSelected) {
			props.select()
		}
	}

	React.useEffect(() => {
		setIsMouseMoved(false)
	}, [isSelected])

	const dots = () => {
		const dots: JSX.Element[] = []
		for (let index = 12; index >= 1; index--) {
			dots.push(
				<p
					key={index}
					className={cx(
						classes.Dots,
						{ [classes.Margin]: index === 4 || index === 8 },
						{ [classes.HideS]: index > 8 && index <= 12 },
						{ [classes.HideXS]: index > 4 }
					)}
				>
					{DOT}
				</p>
			)
		}
		return dots
	}

	return (
		<>
			<div
				id={`${props.id}Div`}
				className={`${classes.CreditCard}`}
				onMouseMove={() => {
					if (!isSelected) setIsMouseMoved(true)
				}}
				onClick={select}
			>
				<div
					className={classes.Logo}
					style={{
						backgroundImage: `url(${creditCardLogoSrc(creditCardType)})`,
					}}
				></div>
				<div className={classes.CardText}>
					<div className={classes.Last4Digits}>
						{dots()}
						<p className={classes.Last}>{last4Digits}</p>
					</div>
					{/* <div>{CreditCardLabel.get(creditCardType)}</div> */}
				</div>

				{/* <img
          className={classes.Remove}
          src={trashIcon}
          alt="Remove"
          onClick={removeHandler}
        /> */}
			</div>
		</>
	)
}

export default CreditCard
