import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { PayCard } from '../../../../store/types/appState'
import { TenderType } from '../../../../types/api/apiEnums'
import CreditCard from '../../CreditCards/CreditCard/CreditCard'
import ECheck from '../../ECheck/ECheck/ECheck'
import AddButton from '../AddButton/AddButton'
import classes from './PayCardSlider.module.css'

export type Props = {
	payCards: PayCard[]
	selectedCardClicked: (selectedCard: PayCard) => void
	add: () => void
	selectedCard: PayCard | null
	setSelectedCard: (selectedCard: PayCard | null) => void
}

const PayCardSlider = ({ payCards, selectedCardClicked, add, selectedCard, setSelectedCard }: Props) => {
	const [currentNumber, setCurrentNumber] = useState(0)

	useEffect(() => {
		if (payCards && payCards.length > 0 && payCards[currentNumber]) {
			setSelectedCard(payCards[currentNumber])
		} else {
			setSelectedCard(null)
		}
	}, [payCards, setSelectedCard, currentNumber])

	const [isMoving, setIsMoving] = useState(false)

	const handleClickOutside = () => {
		setSelectedCard(null)
	}

	const settings = {
		className: classes.Slider,
		dots: true,
		infinite: false,
		centerMode: true,
		focusOnSelect: true,
		centerPadding: '70px',
		slidesToShow: 1,
		arrows: false,
		beforeChange: () => {
			setIsMoving(true)
		},
		afterChange: (current: number) => {
			setCurrentNumber(current)
			setIsMoving(false)
		},
	}

	return (
		<>
			<Slider {...settings}>
				{payCards.map((el) => {
					return (
						<div
							key={el.id}
							className={!!selectedCard && selectedCard.id === el.id ? classes.Grow : classes.Shrink}
						>
							{el.type === TenderType.CreditCard && el.creditCardType && (
								<CreditCard
									id={`creditCardBox${el.id}`}
									handleClickOutside={handleClickOutside}
									last4Digits={el.last4Digits}
									creditCardType={el.creditCardType}
									isSelected={!isMoving && !!selectedCard && selectedCard.id === el.id}
									select={() => {
										selectedCardClicked(el)
									}}
									remove={() => undefined} // props.remove(c.id)}
								/>
							)}

							{el.type === TenderType.ECheck && (
								<ECheck
									id={`eCheckBox${el.id}`}
									handleClickOutside={handleClickOutside}
									last4Digits={el.last4Digits}
									routingNumberLast4Digits={el.routingNumberLast4Digits}
									isSelected={!isMoving && !!selectedCard && selectedCard.id === el.id}
									select={() => {
										selectedCardClicked(el)
									}}
								/>
							)}
						</div>
					)
				})}

				<AddButton onClick={add} isSelected={!isMoving && !selectedCard} id={'addPayCard'} />
			</Slider>
		</>
	)
}

export default PayCardSlider
