import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VerificationType, PayCard, SelectedPayCard } from '../../../../store/types/appState'
import { CreditCardType, TenderType } from '../../../../types/api/apiEnums'
import NumberInput from '../../../ui/Inputs/NumberInput/NumberInput'
import Modal from '../../../ui/modal/Modal/Modal'
import classes from './PayCardsList.module.css'
import PayCardSlider from '../PayCardSlider/PayCardSlider'
import DeletePaymentMethod from '../DeletePaymentMethod/DeletePaymentMethod'
import Header from '../../../ui/layout/Header/Header'
import BottomButtons from '../../../ui/layout/BottomButtons/BottomButtons'
import BottomButton from '../../../ui/layout/BottomButtons/BottomButton'
import bottomButtonsClasses from '../../../ui/layout/BottomButtons/BottomButtons.module.scss'

export type Props = {
	payCards: PayCard[]
	verificationType: VerificationType
	confirm: (data: SelectedPayCard) => void
	add: (tenderType: TenderType) => void
	delete: (PayCard: PayCard) => void
	back: () => void
}

enum VerificationStep {
	Zip,
	Cvv,
}

const CCPayCardsList = (props: Props) => {
	const [t] = useTranslation()

	const [zipCode, setZipCode] = useState<string>('')
	const [isZipValid, setIsZipValid] = useState(true)
	const [cvv, setCvv] = useState<string>('')
	const [isCvvValid, setIsCvvValid] = useState(true)
	const [selectedCard, setSelectedCard] = useState<PayCard | null>(null)
	const [verificationStep, setVerificationStep] = useState(VerificationStep.Zip)
	const [cardClicked, setCardClicked] = useState(false)

	const [isPaymentMethodDeleting, setIsPaymentMethodDeleting] = useState<boolean>(false)

	const showDeleteHandler = () => {
		setIsPaymentMethodDeleting(true)
	}

	const confirmCC = (
		creditCardId: string,
		zipCode: string,
		cvv: string,
		last4Digits: string,
		creditCardType: CreditCardType
	) => {
		props.confirm({
			type: TenderType.CreditCard,
			creditCardId,
			zipCode,
			cvv,
			last4Digits,
			creditCardType,
		})
	}

	const selectCardHandler = (selectedCard: PayCard) => {
		setCardClicked(true)
		setSelectedCard(selectedCard)
		if (selectedCard.type === TenderType.CreditCard && selectedCard.creditCardType) {
			if (props.verificationType & VerificationType.ZipCode) {
				setVerificationStep(VerificationStep.Zip)
			} else if (props.verificationType & VerificationType.Cvv) {
				setVerificationStep(VerificationStep.Cvv)
			} else {
				confirmCC(selectedCard.id, zipCode, cvv, selectedCard.last4Digits, selectedCard.creditCardType)
			}
		}
	}

	const verificationHandler = () => {
		let isCompleted = false
		if (selectedCard) {
			switch (verificationStep) {
				case VerificationStep.Zip:
					if (zipCode.length === 5) {
						setIsZipValid(true)
						if (!(props.verificationType & VerificationType.Cvv)) isCompleted = true
						else setVerificationStep(VerificationStep.Cvv)
					} else setIsZipValid(false)
					break
				case VerificationStep.Cvv:
					if (
						selectedCard.type === TenderType.CreditCard &&
						((selectedCard.creditCardType === CreditCardType.AmericanExpress && cvv.length === 4) ||
							(selectedCard.creditCardType !== CreditCardType.AmericanExpress && cvv.length === 3))
					) {
						setIsCvvValid(true)
						isCompleted = true
					} else setIsCvvValid(false)
					break
			}

			if (isCompleted && selectedCard.type === TenderType.CreditCard && selectedCard.creditCardType) {
				confirmCC(selectedCard.id, zipCode, cvv, selectedCard.last4Digits, selectedCard.creditCardType)
			}
		}
	}

	return (
		<>
			<>
				{cardClicked && selectedCard && selectedCard.type === TenderType.CreditCard ? (
					verificationStep === VerificationStep.Zip ? (
						<Modal
							id='zipVerification'
							cancel={() => {
								setCardClicked(false)
							}}
							ok={verificationHandler}
							title={t('payment.zip-verification')}
							message={t('payment.zip-verification-message')}
							okText={t('payment.verify')}
							cancelText={t('cancel')}
						>
							<div className={classes.Value}>
								<NumberInput
									title={t('payment.zip')}
									maxLength={5}
									value={zipCode}
									valueChange={(value) => setZipCode(value)}
									focus={true}
									isValid={isZipValid}
								/>
							</div>
						</Modal>
					) : (
						<Modal
							id='cvvVerification'
							cancel={() => {
								setCardClicked(false)
							}}
							ok={verificationHandler}
							title={t('payment.cvv-verification')}
							message={t('payment.cvv-verification-message')}
							okText={t('payment.verify')}
							cancelText={t('cancel')}
						>
							<div className={classes.Value}>
								<NumberInput
									title={t('payment.cvv')}
									maxLength={
										selectedCard && selectedCard.creditCardType === CreditCardType.AmericanExpress
											? 4
											: 3
									}
									value={cvv}
									valueChange={(value) => setCvv(value)}
									focus={true}
									isValid={isCvvValid}
								/>
							</div>
						</Modal>
					)
				) : null}
				{isPaymentMethodDeleting && (
					<DeletePaymentMethod
						delete={props.delete}
						selectedCard={selectedCard}
						cancel={() => setIsPaymentMethodDeleting(false)}
					/>
				)}
			</>
			<Header
				title={t('payment.credit-card')}
				subTitle={t('payment.choose-credit-card')}
				showBackButton={true}
				back={props.back}
			/>
			{props.payCards ? (
				<PayCardSlider
					payCards={props.payCards}
					add={() => props.add(TenderType.CreditCard)}
					selectedCardClicked={selectCardHandler}
					selectedCard={selectedCard}
					setSelectedCard={setSelectedCard}
				/>
			) : null}

			{selectedCard && (
				<BottomButtons disableAutoMarginTop={true}>
					<BottomButton
						id='deleteCC'
						className={`${bottomButtonsClasses.ButtonShort} ButtonOutlined`}
						onClick={showDeleteHandler}
						title={t('delete')}
						disabled={undefined}
						grow={undefined}
					/>
					<BottomButton
						id='payCC'
						className={`ButtonPrimary`}
						onClick={() => selectCardHandler(selectedCard)}
						title={t('pay')}
						disabled={undefined}
						grow={undefined}
					/>
				</BottomButtons>
			)}
		</>
	)
}

export default CCPayCardsList
