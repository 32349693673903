import * as actionTypes from '../actions/actionTypes'
import { Actions } from '../actions/stripe'
import { StripeState } from '../types/appState'

const initialState: StripeState = {
	checkoutSessionClientSecret: '',
	paymentIntentClientSecret: '',
	loading: false,
}

const setLoader = (loading: boolean, state: StripeState) => {
	return {
		...state,
		loading: loading,
	}
}

const createCheckoutSessionSuccess = (state: StripeState, clientSecret: string) => {
	return {
		...state,
		loading: false,
		checkoutSessionClientSecret: clientSecret,
	}
}

const createPaymentIntentSuccess = (state: StripeState, clientSecret: string) => {
	return {
		...state,
		loading: false,
		paymentIntentClientSecret: clientSecret,
	}
}

const reducer = (state: StripeState = initialState, action: Actions): StripeState => {
	switch (action.type) {
		case actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_START:
			return setLoader(true, state)
		case actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_FAIL:
			return setLoader(false, state)
		case actionTypes.STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS:
			return createCheckoutSessionSuccess(state, action.clientSecret)
		case actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_START:
			return setLoader(true, state)
		case actionTypes.STRIPE_CONFIRM_PAYMENT_INTENT_FAIL:
			return setLoader(false, state)
		case actionTypes.STRIPE_CREATE_PAYMENT_INTENT_START:
			return setLoader(true, state)
		case actionTypes.STRIPE_CREATE_PAYMENT_INTENT_SUCCESS:
			return createPaymentIntentSuccess(state, action.clientSecret)
		default:
			return state
	}
}

export default reducer
