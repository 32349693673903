import React from 'react'
import classes from './Modal.module.css'
import { useTranslation } from 'react-i18next'

export type Props = {
	title: string
	message?: string
	okText?: string
	cancelText?: string
	ok?: () => void
	cancel?: () => void
	children: (JSX.Element | null)[] | (JSX.Element | null)
	id: string
}

const Modal = (props: Props) => {
	const [t] = useTranslation()

	return (
		<>
			<div className={classes.Backdrop} />
			<div className={classes.Modal}>
				<h2 className={classes.ModalTitle}>{props.title}</h2>
				{props.message && <div className={classes.ModalMessage}>{props.message}</div>}
				<div className={classes.Children}>{props.children}</div>
				<div className={classes.Footer}>
					{props.cancel ? (
						<button
							id={`${props.id}ModalCancelBtn`}
							className={`${classes.Button} ButtonText`}
							onClick={props.cancel}
						>
							{props.cancelText ? t(props.cancelText) : t('cancel')}
						</button>
					) : null}
					{props.ok ? (
						<button
							id={`${props.id}ModalOkBtn`}
							className={`${classes.Button} ButtonPrimary`}
							onClick={props.ok}
						>
							{t(props.okText ? props.okText : t('ok'))}
						</button>
					) : null}
				</div>
			</div>
		</>
	)
}

export default Modal
