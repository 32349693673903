import React from 'react'
import classes from './PaymentLayout.module.css'
import logo from '../../../../assets/logo.svg'
import ErrorModal from '../../modal/ErrorModal/ErrorModal'
import { useDispatch, useSelector } from 'react-redux'
import { clearError } from '../../../../store/actions'
import { AppState, ErrorState } from '../../../../store/types/appState'
import { useTranslation } from 'react-i18next'
import Loader from '../../Loader/Loader'

export type Props = {
	children: (JSX.Element | null)[] | (JSX.Element | null)
	isLoading: boolean
	showContentOnLoading: boolean
}

const PaymentLayout = (props: Props) => {
	const [t] = useTranslation()

	const error = useSelector<AppState, ErrorState>((state) => state.error)

	const dispatch = useDispatch()

	return (
		<>
			{error.message && (
				<ErrorModal
					title={t('error.error')}
					message={t(error.message)}
					ok={() => dispatch(clearError())}
					clearable={error.clearable}
				/>
			)}
			{props.isLoading && <Loader />}
			<div className={classes.FlexContainer}>
				<header className={classes.PaymentHeader}>
					<img className={classes.Logo} src={logo} alt='logo' />
				</header>
				<main className={classes.PaymentMain}>
					{(props.showContentOnLoading || !props.isLoading) && props.children}
				</main>
			</div>
		</>
	)
}

export default PaymentLayout
