import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { t } from 'i18next'
import BottomButton from '../../ui/layout/BottomButtons/BottomButton'
import BottomButtons from '../../ui/layout/BottomButtons/BottomButtons'
import { useDispatch } from 'react-redux'
import { confirmPaymentIntent } from '../../../store/actions/stripe'
import { useNavigate } from 'react-router-dom'

const StripePaymentElementsForm = () => {
	const [isProcessing, setIsProcessing] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const stripe = useStripe()
	const elements = useElements()

	const submitHandler = async () => {
		if (!stripe || !elements) return

		elements.submit().then((data) => {
			if (!data.error) {
				setIsProcessing(true)

				dispatch(confirmPaymentIntent(stripe, elements, navigate))

				setIsProcessing(false)
			}
		})
	}

	return (
		<>
			<div style={{ margin: '16px' }}>
				<PaymentElement />
			</div>
			{stripe && elements && (
				<BottomButtons>
					<BottomButton
						id='stripeUSSubmitButton'
						className={`ButtonPrimary`}
						onClick={submitHandler}
						disabled={isProcessing}
						title={t('pay')}
						grow={undefined}
					/>
				</BottomButtons>
			)}
		</>
	)
}

export default StripePaymentElementsForm
