export const AUTH = 'AUTH'

export const UPDATE_TRANSACTION_INFO = 'UPDATE_TRANSACTION_INFO'

export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS'
export const GET_TRANSACTION_FAIL = 'GET_TRANSACTION_FAIL'
export const GET_TRANSACTION_START = 'GET_TRANSACTION_START'

export const GET_ONE_TIME_PAYMENT_SUCCESS = 'GET_ONE_TIME_PAYMENT_SUCCESS'
export const GET_ONE_TIME_PAYMENT_FAIL = 'GET_ONE_TIME_PAYMENT_FAIL'
export const GET_ONE_TIME_PAYMENT_START = 'GET_ONE_TIME_PAYMENT_START'

export const TRANSACTION_COMPLETED = 'TRANSACTION_COMPLETED'
export const TRANSACTION_FAIL = 'TRANSACTION_FAIL'
export const TRANSACTION_START = 'TRANSACTION_START'

export const SET_TRANSACTION_CREDIT_CARD = 'SET_TRANSACTION_CREDIT_CARD'
export const SET_TRANSACTION_ECHECK = 'SET_TRANSACTION_ECHECK'
export const SET_PAYMENT_STEP = 'SET_PAYMENT_STEP'
export const ADD_CREDIT_CARD_STEP = 'ADD_CREDIT_CARD_STEP'
export const ADD_ECHECK_STEP = 'ADD_ECHECK_STEP'

export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS'
export const DELETE_CREDIT_CARD_FAIL = 'DELETE_CREDIT_CARD_FAIL'
export const DELETE_CREDIT_CARD_START = 'DELETE_CREDIT_CARD_START'

export const RESTORE_POST_INIT = 'RESTORE_POST_INIT'

export const GET_MERCHANT_SUCCESS = 'GET_MERCHANT_SUCCESS'
export const GET_MERCHANT_FAIL = 'GET_MERCHANT_FAIL'
export const GET_MERCHANT_START = 'GET_MERCHANT_START'

export const SET_NMI_CC_PAYMENT_TOKEN = 'SET_NMI_CC_PAYMENT_TOKEN'
export const SET_NMI_CC_PAYMENT_DATA = 'SET_NMI_CC_PAYMENT_DATA'

export const SET_NMI_ECHECK_PAYMENT_TOKEN = 'SET_NMI_ECHECK_PAYMENT_TOKEN'

export const PAY_FOR_NMI_TRANSACTION_END = 'PAY_FOR_NMI_TRANSACTION_END'
export const PAY_FOR_NMI_TRANSACTION_START = 'PAY_FOR_NMI_TRANSACTION_START'

export const SET_NMI_TRANSACTION_CREDIT_CARD = 'SET_NMI_TRANSACTION_CREDIT_CARD'
export const SET_NMI_TRANSACTION_ECHECK = 'SET_NMI_TRANSACTION_ECHECK'

export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA'

export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL'
export const GET_PAYMENT_METHODS_START = 'GET_PAYMENT_METHODS_START'

export const SET_MANAGE_PAYMENT_METHODS_STEP = 'SET_MANAGE_PAYMENT_METHODS_STEP'

export const GET_MANAGE_PAYMENT_METHODS_SUCCESS = 'GET_MANAGE_PAYMENT_METHODS_SUCCESS'
export const GET_MANAGE_PAYMENT_METHODS_FAIL = 'GET_MANAGE_PAYMENT_METHODS_FAIL'
export const GET_MANAGE_PAYMENT_METHODS_START = 'GET_MANAGE_PAYMENT_METHODS_START'

export const ADD_NMI_PAYMENT_METHOD_SUCCESS = 'ADD_NMI_PAYMENT_METHOD_SUCCESS'
export const ADD_NMI_PAYMENT_METHOD_FAIL = 'ADD_NMI_PAYMENT_METHOD_FAIL'
export const ADD_NMI_PAYMENT_METHOD_DECLINE = 'ADD_NMI_PAYMENT_METHOD_DECLINE'
export const ADD_NMI_PAYMENT_METHOD_START = 'ADD_NMI_PAYMENT_METHOD_START'

export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS'
export const ADD_PAYMENT_METHOD_FAIL = 'ADD_PAYMENT_METHOD_FAIL'
export const ADD_PAYMENT_METHOD_DECLINE = 'ADD_PAYMENT_METHOD_DECLINE'
export const ADD_PAYMENT_METHOD_START = 'ADD_PAYMENT_METHOD_START'

export const RESTOR_MANAGE_PAYMENT_METHODS_POST_INIT = 'RESTOR_MANAGE_PAYMENT_METHODS_POST_INIT'

export const DELETE_NMI_PAYMENT_METHOD_SUCCESS = 'DELETE_NMI_PAYMENT_METHOD_SUCCESS'
export const DELETE_NMI_PAYMENT_METHOD_FAIL = 'DELETE_NMI_PAYMENT_METHOD_FAIL'
export const DELETE_NMI_PAYMENT_METHOD_DECLINE = 'DELETE_NMI_PAYMENT_METHOD_DECLINE'
export const DELETE_NMI_PAYMENT_METHOD_START = 'DELETE_NMI_PAYMENT_METHOD_START'

export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAIL = 'DELETE_PAYMENT_METHOD_FAIL'
export const DELETE_PAYMENT_METHOD_DECLINE = 'DELETE_PAYMENT_METHOD_DECLINE'
export const DELETE_PAYMENT_METHOD_START = 'DELETE_PAYMENT_METHOD_START'

export const SET_REDIRECT_TYPE = 'SET_REDIRECT_TYPE'
export const SET_REDIRECT_STATUS_TYPE = 'SET_REDIRECT_STATUS_TYPE'

export const CREATE_NMI_AUTOPAYMENT_END = 'CREATE_NMI_AUTOPAYMENT_END'
export const CREATE_NMI_AUTOPAYMENT_START = 'CREATE_NMI_AUTOPAYMENT_START'

export const GET_REDIRECT_SETTINGS_SUCCESS = 'GET_REDIRECT_SETTINGS_SUCCESS'
export const GET_REDIRECT_SETTINGS_START = 'GET_REDIRECT_SETTINGS_START'
export const GET_REDIRECT_SETTINGS_FAIL = 'GET_REDIRECT_SETTINGS_FAIL'

export const GET_REDIRECT_STATUS_SUCCESS = 'GET_REDIRECT_STATUS_SUCCESS'
export const GET_REDIRECT_STATUS_START = 'GET_REDIRECT_STATUS_START'
export const GET_REDIRECT_STATUS_FAIL = 'GET_REDIRECT_STATUS_FAIL'

export const START_USER_TIMER_SUCCESS = 'START_USER_TIMER_SUCCESS'
export const START_USER_TIMER_FAIL = 'START_USER_TIMER_FAIL'
export const SET_USER_TIMER_EXPIRED = 'SET_USER_TIMER_EXPIRED'
export const SET_USER_TIMER_DURATION = 'SET_USER_TIMER_DURATION'
export const START_AUTH = 'START_AUTH'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'

export const SET_TRANSACTION_DIGITAL_WALLET = 'SET_TRANSACTION_DIGITAL_WALLET'

export const SET_TRANSACTION_AUTO_PAYMENT = 'SET_TRANSACTION_AUTO_PAYMENT'

export const STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS = 'STRIPE_CREATE_CHECKOUT_SESSION_SUCCESS'
export const STRIPE_CREATE_CHECKOUT_SESSION_FAIL = 'STRIPE_CREATE_CHECKOUT_SESSION_FAIL'
export const STRIPE_CREATE_CHECKOUT_SESSION_START = 'STRIPE_CREATE_CHECKOUT_SESSION_START'

export const STRIPE_CREATE_PAYMENT_INTENT_SUCCESS = 'STRIPE_CREATE_PAYMENT_INTENT_SUCCESS'
export const STRIPE_CREATE_PAYMENT_INTENT_FAIL = 'STRIPE_CREATE_PAYMENT_INTENT_FAIL'
export const STRIPE_CREATE_PAYMENT_INTENT_START = 'STRIPE_CREATE_PAYMENT_INTENT_START'

export const STRIPE_CONFIRM_PAYMENT_INTENT_FAIL = 'STRIPE_CONFIRM_PAYMENT_INTENT_FAIL'
export const STRIPE_CONFIRM_PAYMENT_INTENT_START = 'STRIPE_CONFIRM_PAYMENT_INTENT_START'

export const POLL_REDIRECT_DATA_START = 'POLL_REDIRECT_DATA_START'
export const POLL_REDIRECT_DATA_END = 'POLL_REDIRECT_DATA_END'
