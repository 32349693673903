import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { auth, getMerchant } from '../../store/actions'
import { setRedirectType } from '../../store/actions/redirect'
import { AppState, ProviderType, RedirectStatus, RedirectType } from '../../store/types/appState'
import CustomPayment from './CustomPayment'
import Payment from './Payment'
import { authSession } from '../../store/actions/auth'
import { setUserTimerExpired } from '../../store/actions/userTimer'
import { isFinalStatus } from '../../utils/utils'

const OneTimePayment = () => {
	const dispatch = useDispatch()

	const { token } = useParams()

	const authToken = useSelector<AppState>((state) => state.auth.token)
	const isTokenValid = useSelector<AppState>((state) => state.auth.isTokenValid)
	const providerType = useSelector<AppState>((state) => state.merchant.providerType)
	const isUserTimerExpired = useSelector<AppState, boolean>((state) => state.userTimer.isUserTimerExpired)
	const userSessionDuration = useSelector<AppState, number>((state) => state.userTimer.userTimerDuration)

	const redirectStatus = useSelector<AppState, RedirectStatus>((state) => state.redirectStatus.status)

	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

	useEffect(() => {
		if (userSessionDuration && !isUserTimerExpired) {
			setTimer(
				setTimeout(
					() => {
						dispatch(setUserTimerExpired(true))
					},
					providerType === ProviderType.StripeEurope || providerType === ProviderType.StripeUS
						? userSessionDuration + 30000
						: userSessionDuration
				)
			)
		}
	}, [userSessionDuration, dispatch, isUserTimerExpired, providerType])

	useEffect(() => {
		if (
			(providerType === ProviderType.StripeEurope || providerType === ProviderType.StripeUS) &&
			(isUserTimerExpired || isFinalStatus(redirectStatus))
		) {
			window.location.href = `${window.location.href}/return`
		}
	}, [isUserTimerExpired, providerType, redirectStatus])

	useEffect(() => {
		return () => {
			if (timer !== null) {
				clearTimeout(timer)
			}
		}
	}, [timer])

	useEffect(() => {
		if (authToken !== null && isTokenValid === true) {
			dispatch(getMerchant())
		}
	}, [authToken, isTokenValid, dispatch])

	useEffect(() => {
		dispatch(setRedirectType(RedirectType.OneTimePayment))
	}, [dispatch])

	useEffect(() => {
		dispatch(auth(token!))
	}, [token, dispatch])

	useEffect(() => {
		if (authToken !== null) {
			dispatch(authSession())
		}
	}, [authToken, dispatch])

	let paymentComponent
	switch (providerType) {
		case ProviderType.StripeEurope:
		case ProviderType.StripeUS:
			paymentComponent = <CustomPayment />
			break
		case ProviderType.Unknown:
			paymentComponent = null
			break
		default:
			paymentComponent = <Payment timer={timer} />
			break
	}

	return paymentComponent
}

export default OneTimePayment
