import axios from '../../utils/axios-tezpay'
import { AnyAction } from 'redux'
import ApiMethods from '../../constants/urls'
import { PortalHideControl } from '../../types/api/apiEnums'
import { AppState, RedirectState, RedirectStatus, RedirectType, TransactionStatus } from '../types/appState'
import * as actionTypes from './actionTypes'
import { setError } from './error'
import { ThunkDispatch } from 'redux-thunk'
import { getTransactionFail, getTransactionStart, getTransactionSuccess } from './transaction'

export const setRedirectType = (redirectType: RedirectType) => {
	return {
		type: actionTypes.SET_REDIRECT_TYPE as typeof actionTypes.SET_REDIRECT_TYPE,
		redirectType: redirectType,
	}
}

export const setRedirectStatus = (status: RedirectStatus) => {
	return {
		type: actionTypes.SET_REDIRECT_STATUS_TYPE as typeof actionTypes.SET_REDIRECT_STATUS_TYPE,
		status: status,
	}
}

export const getCustomSettingsStart = () => {
	return {
		type: actionTypes.GET_REDIRECT_SETTINGS_START as typeof actionTypes.GET_REDIRECT_SETTINGS_START,
	}
}

export const getCustomSettingsFail = () => {
	return {
		type: actionTypes.GET_REDIRECT_SETTINGS_FAIL as typeof actionTypes.GET_REDIRECT_SETTINGS_FAIL,
	}
}

export const getCustomSettingsSuccess = (hideControls: PortalHideControl | null) => {
	return {
		type: actionTypes.GET_REDIRECT_SETTINGS_SUCCESS as typeof actionTypes.GET_REDIRECT_SETTINGS_SUCCESS,
		hideControls: hideControls,
	}
}

export const pollRedirectDataStart = () => {
	return {
		type: actionTypes.POLL_REDIRECT_DATA_START as typeof actionTypes.POLL_REDIRECT_DATA_START,
	}
}

export const pollRedirectDataEnd = () => {
	return {
		type: actionTypes.POLL_REDIRECT_DATA_END as typeof actionTypes.POLL_REDIRECT_DATA_END,
	}
}

export const getRedirectStatusStart = () => {
	return {
		type: actionTypes.GET_REDIRECT_STATUS_START as typeof actionTypes.GET_REDIRECT_STATUS_START,
	}
}

export const getRedirectStatusFail = () => {
	return {
		type: actionTypes.GET_REDIRECT_STATUS_FAIL as typeof actionTypes.GET_REDIRECT_STATUS_FAIL,
	}
}

export const getRedirectStatusSuccess = (status: RedirectStatus) => {
	return {
		type: actionTypes.GET_REDIRECT_STATUS_SUCCESS as typeof actionTypes.GET_REDIRECT_STATUS_SUCCESS,
		status: status,
	}
}

export const getRedirectSettings = () => {
	return (dispatch: ThunkDispatch<RedirectState, object, AnyAction>) => {
		dispatch(getCustomSettingsStart())
		axios
			.get(ApiMethods.Portal.RedirectCustomSettings)
			.then((response) => {
				const data = response.data.data
				dispatch(getCustomSettingsSuccess(data.hideControls))
			})
			.catch((error) => {
				console.error(error)
				dispatch(getCustomSettingsFail())
				if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
				else dispatch(setError('error.api-error', false))
			})
	}
}

export const getRedirectData = (displayError: boolean = true) => {
	return (dispatch: ThunkDispatch<RedirectState, object, AnyAction>) => {
		dispatch(getRedirectStatusStart())
		dispatch(getTransactionStart())
		axios
			.get(ApiMethods.Portal.RedirectData)
			.then((response) => {
				const data = response.data.data
				dispatch(getRedirectStatusSuccess(data.status))
				dispatch(
					getTransactionSuccess(
						data.amount,
						data.transactionStatus,
						data.redirectUrl,
						data.last4Digits,
						data.cardType,
						data.tenderType
					)
				)
			})
			.catch((error) => {
				console.error(error)
				dispatch(getRedirectStatusFail())
				dispatch(getTransactionFail())
				if (displayError) {
					if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
					else dispatch(setError('error.api-error', false))
				}
			})
	}
}

export const pollRedirectData = () => {
	return (dispatch: ThunkDispatch<RedirectState, object, AnyAction>, getState: () => AppState) => {
		dispatch(pollRedirectDataStart())
		pollRedirectDataInner(dispatch, getState, 0)
		dispatch(pollRedirectDataEnd())
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config = (window as any).config

const pollRedirectDataInner = (
	dispatch: ThunkDispatch<RedirectState, object, AnyAction>,
	getState: () => AppState,
	attempt: number
) => {
	dispatch(getRedirectStatusStart())
	dispatch(getTransactionStart())
	axios
		.get(ApiMethods.Portal.RedirectData)
		.then((response) => {
			const data = response.data.data
			dispatch(getRedirectStatusSuccess(data.status))
			dispatch(
				getTransactionSuccess(
					data.amount,
					data.transactionStatus,
					data.redirectUrl,
					data.last4Digits,
					data.cardType,
					data.tenderType
				)
			)

			const isUserTimerExpired = getState().userTimer.isUserTimerExpired

			if (
				(data.status === TransactionStatus.Unknown || data.status === TransactionStatus.Pending) &&
				!isUserTimerExpired
			) {
				let nextTimeout = config.pullingRedirectDataStartInterval * config.pullingRedirectDataBackOff ** attempt
				if (config.pullingRedirectDataMaxInterval < nextTimeout)
					nextTimeout = config.pullingRedirectDataMaxInterval

				setTimeout(() => pollRedirectDataInner(dispatch, getState, attempt + 1), nextTimeout)
			}
		})
		.catch((error) => {
			console.error(error)
			dispatch(getRedirectStatusFail())
			dispatch(getTransactionFail())
			if (error.response && error.response.status === 401) dispatch(setError('error.unauthorized', false))
			else dispatch(setError('error.api-error', false))
		})
}

export type Actions =
	| ReturnType<typeof setRedirectType>
	| ReturnType<typeof setRedirectStatus>
	| ReturnType<typeof getCustomSettingsStart>
	| ReturnType<typeof getCustomSettingsFail>
	| ReturnType<typeof getCustomSettingsSuccess>
	| ReturnType<typeof getRedirectStatusStart>
	| ReturnType<typeof getRedirectStatusFail>
	| ReturnType<typeof getRedirectStatusSuccess>
