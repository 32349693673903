import * as actionTypes from '../actions/actionTypes'
import { AuthState } from '../types/appState'
import { Actions } from '../actions/auth'

const initialState: AuthState = {
	token: null,
	isTokenValid: false,
}

const auth = (state: AuthState, token: string) => {
	return {
		...state,
		token: token,
	}
}

const checkSessionActivityStart = (state: AuthState) => {
	return {
		...state,
	}
}

const checkSessionActivityFail = (state: AuthState) => {
	return {
		...state,
		isTokenValid: false,
	}
}

const checkSessionActivitySuccess = (state: AuthState, isTokenValid: boolean): AuthState => {
	return {
		...state,
		isTokenValid: isTokenValid,
	}
}

const reducer = (state: AuthState = initialState, action: Actions): AuthState => {
	switch (action.type) {
		case actionTypes.AUTH:
			return auth(state, action.token)
		case actionTypes.START_AUTH:
			return checkSessionActivityStart(state)
		case actionTypes.AUTH_FAIL:
			return checkSessionActivityFail(state)
		case actionTypes.AUTH_SUCCESS:
			return checkSessionActivitySuccess(state, action.isTokenValid)
		default:
			return state
	}
}

export default reducer
