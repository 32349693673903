import React from 'react'
import { AppState, ProviderType } from '../../../store/types/appState'
import NMIGooglePayButton from './NMIGooglePayButton'
import { useSelector } from 'react-redux'

const GooglePayButton = () => {
	const providerType = useSelector<AppState>((x) => x.merchant.providerType)

	let googlePayButtonComponent
	switch (providerType) {
		case ProviderType.NMI:
		case ProviderType.ProPayCNP_NMI:
			googlePayButtonComponent = <NMIGooglePayButton />
			break
		default:
			googlePayButtonComponent = null
			break
	}

	return <>{googlePayButtonComponent}</>
}

export default GooglePayButton
