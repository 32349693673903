import cx from 'classnames'
import React from 'react'
import classes from './ECheck.module.scss'

const DOT = '•'

export type Props = {
	last4Digits: string
	routingNumberLast4Digits: string
	isSelected: boolean
	select: () => void
	handleClickOutside: () => void
	id: string
}

const ECheck = (props: Props) => {
	const { last4Digits, isSelected, routingNumberLast4Digits } = props
	const [isMouseMoved, setIsMouseMoved] = React.useState(false)

	const select = () => {
		if (!isMouseMoved && isSelected) {
			props.select()
		}
	}

	React.useEffect(() => {
		setIsMouseMoved(false)
	}, [isSelected])

	const dots = () => {
		const dots: JSX.Element[] = []
		for (let index = 7; index >= 1; index--) {
			dots.push(
				<p
					key={index}
					className={cx(
						classes.Dots,
						{ [classes.HideS]: index > 8 && index <= 12 },
						{ [classes.HideXS]: index > 4 }
					)}
				>
					{DOT}
				</p>
			)
		}
		return dots
	}

	return (
		<div
			id={`${props.id}Div`}
			className={classes.ECheck}
			onMouseMove={() => {
				if (!isSelected) setIsMouseMoved(true)
			}}
			onClick={select}
		>
			<div className={classes.CardText}>
				<div className={classes.Last4Digits}>
					{dots()}
					<p className={classes.Routing}>{routingNumberLast4Digits}</p>
					{dots()}
					<p className={classes.Last}>{last4Digits}</p>
				</div>
			</div>
		</div>
	)
}

export default ECheck
