import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddCreditCard from '../../components/Payment/CreditCards/AddCreditCard/AddCreditCard'
import AddECheck from '../../components/Payment/ECheck/AddECheck/AddECheck'
import PaymentConfirmation from '../../components/Payment/PaymentConfirmation/PaymentConfirmation'
import PaymentDecline from '../../components/Payment/PaymentDeclined/PaymentDeclined'
import PaymentSuccess from '../../components/Payment/PaymentSuccess/PaymentSuccess'
import PaymentLayout from '../../components/ui/layout/PaymentLayout/PaymentLayout'
import {
	AppState,
	TransactionStatus,
	PaymentStep,
	PayCard,
	SelectedPayCard,
	TransactionState,
	MerchantState,
	PaymentMethodsState,
	PaymentMethodProviderType,
	RedirectStatusState,
	RedirectStatus,
} from '../../store/types/appState'

import {
	deletePaymentMethod,
	setTransactionCreditCard,
	setTransactionECheck,
	restorePostInit,
} from '../../store/actions'

import { TenderType } from '../../types/api/apiEnums'

import {
	addCreditCardStep,
	addECheckStep,
	setExistingTransactionCreditCard,
	setExistingTransactionECheck,
	setPaymentStep,
} from '../../store/actions/transaction'
import PaymentUserTimeout from '../../components/Payment/PaymentUserTimeout/PaymentUserTimeout'
import PaymentMethods from '../../components/Payment/PaymentMethods/PaymentMethods'
import CCPayCardsList from '../../components/Payment/PayCards/PayCardsList/CCPayCardsList'
import ACHPayCardsList from '../../components/Payment/PayCards/PayCardsList/ACHPayCardsList'
import { getRedirectData } from '../../store/actions/redirect'

export type Props = {
	payHandler: () => void
}

const GenericPayment = (props: Props) => {
	const dispatch = useDispatch()

	const transactionData = useSelector<AppState, TransactionState>((state) => state.transaction)

	const merchant = useSelector<AppState, MerchantState>((state) => state.merchant)

	const redirectStatus = useSelector<AppState, RedirectStatusState>((state) => state.redirectStatus)

	const paymentMethods = useSelector<AppState, PaymentMethodsState>((state) => state.paymentMethods)

	const isUserTimerExpired = useSelector<AppState, boolean>((state) => state.userTimer.isUserTimerExpired)

	const authToken = useSelector<AppState>((state) => state.auth.token)
	const isTokenValid = useSelector<AppState, boolean>((state) => state.auth.isTokenValid)

	useEffect(() => {
		if (transactionData.paymentStep === PaymentStep.None && transactionData.status === TransactionStatus.Pending) {
			if (merchant.achEnabled && merchant.availablePaymentMethodTypeCount === 1)
				dispatch(setPaymentStep(PaymentStep.ChooseACHMethod))
			else if (merchant.ccEnabled && merchant.availablePaymentMethodTypeCount === 1)
				dispatch(setPaymentStep(PaymentStep.ChooseCCMethod))
			else if (merchant.availablePaymentMethodTypeCount > 1) dispatch(setPaymentStep(PaymentStep.ChooseMethod))
		}
	}, [
		transactionData.paymentStep,
		transactionData.status,
		merchant.achEnabled,
		merchant.ccEnabled,
		merchant.availablePaymentMethodTypeCount,
		dispatch,
	])

	useEffect(() => {
		if (authToken !== null && isTokenValid === true) {
			dispatch(getRedirectData())
		}
	}, [authToken, isTokenValid, dispatch])

	useEffect(() => {
		if (
			redirectStatus.status !== RedirectStatus.BeforePayment &&
			transactionData.status === TransactionStatus.Pending &&
			!transactionData.loading &&
			!redirectStatus.loading
		) {
			dispatch(getRedirectData())
		}
	}, [redirectStatus.status, redirectStatus.loading, transactionData.status, transactionData.loading, dispatch])

	const redirectHandler = () => {
		window.location.href = transactionData.redirectUrl
	}

	const payCardsCC = () => {
		const data: PayCard[] = []

		if (paymentMethods.availableCreditCards) {
			for (const el of paymentMethods.availableCreditCards) {
				data.push({
					type: TenderType.CreditCard,
					id: el.id,
					creditCardType: el.creditCardType,
					last4Digits: el.last4Digits,
					name: el.last4Digits,
				})
			}
		}

		return data
	}

	const payCardsACH = () => {
		const data: PayCard[] = []

		if (paymentMethods.availableEChecks) {
			for (const el of paymentMethods.availableEChecks) {
				data.push({
					type: TenderType.ECheck,
					id: el.id,
					last4Digits: el.last4Digits,
					routingNumberLast4Digits: el.routingNumberLast4Digits,
				})
			}
		}

		return data
	}

	const setTransactionPayCard = (payCard: SelectedPayCard) => {
		if (payCard.type === TenderType.CreditCard) {
			dispatch(
				setExistingTransactionCreditCard(
					payCard.creditCardId,
					payCard.zipCode,
					payCard.cvv,
					payCard.last4Digits,
					payCard.creditCardType
				)
			)
		} else if (payCard.type === TenderType.ECheck) {
			dispatch(setExistingTransactionECheck(payCard.eCheckId, payCard.last4Digits))
		}
	}

	const deletePaymentMethodHandler = (payCard: PayCard) => {
		dispatch(deletePaymentMethod(payCard.id, payCard.type))
	}

	const backHandler = () => {
		window.history.back()
	}

	const showBackButton = merchant.showBackButton

	const loading =
		authToken !== null &&
		isTokenValid &&
		(transactionData.loading || merchant.loading || paymentMethods.loading || redirectStatus.loading)

	const transactionAmount = transactionData.amount

	const chooseACHHandler = () => {
		if (paymentMethods.availableEChecks === null || paymentMethods.availableEChecks.length > 0)
			dispatch(setPaymentStep(PaymentStep.ChooseACHMethod))
		else dispatch(addECheckStep())
	}

	const chooseCCHandler = () => {
		if (paymentMethods.availableCreditCards === null || paymentMethods.availableCreditCards.length > 0)
			dispatch(setPaymentStep(PaymentStep.ChooseCCMethod))
		else dispatch(addCreditCardStep())
	}

	return (
		<>
			<PaymentLayout isLoading={loading} showContentOnLoading={true}>
				{transactionData.status === TransactionStatus.Accepted && !loading ? (
					<PaymentSuccess
						name={transactionData.name}
						amount={transactionAmount}
						last4Digits={transactionData.last4Digits}
						creditCardType={transactionData.creditCardType}
						ok={redirectHandler}
					/>
				) : transactionData.status === TransactionStatus.Declined ? (
					<PaymentDecline
						amount={transactionAmount}
						last4Digits={transactionData.last4Digits}
						creditCardType={transactionData.creditCardType}
						cancel={redirectHandler}
						tryAgain={() => dispatch(restorePostInit())}
					/>
				) : transactionData.status === TransactionStatus.UserTimeout || isUserTimerExpired ? (
					<PaymentUserTimeout
						amount={transactionAmount}
						last4Digits={transactionData.last4Digits}
						creditCardType={transactionData.creditCardType}
						cancel={redirectHandler}
						tryAgain={() => dispatch(restorePostInit())}
					/>
				) : transactionData.paymentStep === PaymentStep.ChooseMethod ? (
					<PaymentMethods
						back={backHandler}
						showBackButton={showBackButton}
						chooseACH={chooseACHHandler}
						chooseCC={chooseCCHandler}
					/>
				) : transactionData.paymentStep === PaymentStep.ChooseCCMethod ? (
					<CCPayCardsList
						payCards={payCardsCC()}
						confirm={setTransactionPayCard}
						verificationType={merchant.verificationType}
						add={() => dispatch(addCreditCardStep())}
						delete={deletePaymentMethodHandler}
						back={() => dispatch(setPaymentStep(PaymentStep.ChooseMethod))}
					/>
				) : transactionData.paymentStep === PaymentStep.ChooseACHMethod ? (
					<ACHPayCardsList
						payCards={payCardsACH()}
						confirm={setTransactionPayCard}
						add={() => dispatch(addECheckStep())}
						delete={deletePaymentMethodHandler}
						back={() => dispatch(setPaymentStep(PaymentStep.ChooseMethod))}
					/>
				) : transactionData.paymentStep === PaymentStep.Confirm ? (
					<PaymentConfirmation
						name={transactionData.name}
						creditCardType={transactionData.creditCardType}
						amount={transactionAmount}
						last4Digits={transactionData.last4Digits}
						isPayAvailable={redirectStatus.status !== RedirectStatus.DuringPayment}
						pay={props.payHandler}
						back={() => dispatch(restorePostInit())}
					/>
				) : transactionData.paymentStep === PaymentStep.AddPayCard &&
				  transactionData.tenderType === TenderType.CreditCard ? (
					<AddCreditCard
						back={() => dispatch(restorePostInit())}
						next={(params: PaymentMethodProviderType) =>
							dispatch(setTransactionCreditCard(params.last4Digits, params.ccType))
						}
						verificationType={merchant.verificationType}
						providerType={merchant.providerType}
					/>
				) : transactionData.paymentStep === PaymentStep.AddPayCard &&
				  transactionData.tenderType === TenderType.ECheck ? (
					<AddECheck
						back={() => dispatch(restorePostInit())}
						next={(name: string, last4Digits: string) => dispatch(setTransactionECheck(last4Digits, name))}
						providerType={merchant.providerType}
					/>
				) : null}
			</PaymentLayout>
		</>
	)
}

export default GenericPayment
