import { CreditCardType } from '../types/api/apiEnums'

export const matchCreditCardType = (type: string): CreditCardType => {
	switch (type) {
		case 'visa':
			return CreditCardType.Visa
		case 'amex':
			return CreditCardType.AmericanExpress
		case 'diners':
			return CreditCardType.DinersClub
		case 'discover':
			return CreditCardType.Discover
		case 'jcb':
			return CreditCardType.JCB
		case 'mastercard':
			return CreditCardType.MasterCard
		default:
			return CreditCardType.Invalid
	}
}
